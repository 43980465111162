import { render, staticRenderFns } from "./AddSchoolAdmin.vue?vue&type=template&id=acd875ae&"
import script from "./AddSchoolAdmin.vue?vue&type=script&lang=ts&"
export * from "./AddSchoolAdmin.vue?vue&type=script&lang=ts&"
import style0 from "./AddSchoolAdmin.vue?vue&type=style&index=0&id=acd875ae&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports