import Storage from "@/util/Storage";
import {addSchoolAdmin, updateSchoolAdmin} from "@/Api/Platform/bascs";
import {Data} from "@/views/PlatformAdmin/AddSchoolAdmin/Model";
import {Toast} from "vant";
// 这里
// 添加学校
export function addSchool(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    if ( !body["name"] ){
        Toast("请输入社区名称")
    }
    Toast.loading({ duration:0,message:"添加中~~" })
    return Promise.resolve(addSchoolAdmin({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}

// 修改学校
export function updateSchool(body:any = {},oldData:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    body["id"] = oldData["id"]
    let num = 0;
    Data.forEach(item=>{
        if ( body[item] != oldData[item] ) num ++;
    })
    if ( num <= 0 ) {
        Toast("请修改内容");
        return
    }
    Toast.loading({ duration:0,message:"修改中~~" })
    return Promise.resolve(updateSchoolAdmin({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
