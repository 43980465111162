

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import WxJsSdkStoreType from "@/store/modules/Global/WxJsSdk/indexType";
import {WxJsSdkConfig} from "@/Api/Shop";
import Storage from "@/util/Storage";
declare const wx :any;

@Module({
    name:"WxJsSdk",
    store,
    dynamic: true,
})
export default class WxJsSdk extends VuexModule implements WxJsSdkStoreType{
    configStatus = false
    get getConfigStatus(){ return this.configStatus }

    @Mutation
    SetConfigStatus(data:boolean){
        this.configStatus = data
    }

    get getLocation():Promise<any> {
        if (this.configStatus) return new Promise(resolve => {
            wx.getLocation({type: 'wgs84', success: (res: any) => resolve(res)});
        });
        else return Promise.reject();
    }


    @Action
    SetWxJsSdkConfig(): void {
        const userId = Storage.GetData_ && Storage.GetData_("userId")
        const token = Storage.GetData_ && Storage.GetData_("token")
        WxJsSdkConfig({ userId,token },{ url:window.location.href || 'https://mobile.31shequ.com' }).then(res=>{
            let { appid = "",noncestr = "",timestamp = "",signature = "", } = res.data;
            wx.config({
                debug: process.env.NODE_ENV === "development" ? true : false,
                appId: appid,
                timestamp: timestamp,
                nonceStr: noncestr,
                signature: signature,
                jsApiList: ["getLocation"]
            })
            wx.ready(()=>{
                this.SetConfigStatus(true)
            });
            wx.error((cat:any)=>{
                console.info(cat)
                this.SetConfigStatus(false)
            });
        })
    }

}

export const WxJsSdkStore = getModule( WxJsSdk )
