


import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import FriendLocationStoreType, {
    locationAddressDataType,
    locationDataType,
    locationUpDataType
} from "@/views/Friend/FriendAddress/Store/indexType";

@Module({
    name:"FriendLocation",
    store,
    dynamic: true,
})
export default class FriendLocation extends VuexModule implements FriendLocationStoreType{
    locationUpData = {
        pageNo:1,
        pageSize:15
    }
    get getLocationUpData(){return this.locationUpData}
    @Mutation
    setLocationUpData(data: locationUpDataType): void {
        this.locationUpData = data
    }

    locationData: locationDataType = {
        latitude:"",
        longitude:""
    };
    get getLocationData(){ return this.locationData }
    @Mutation
    setLocationData(data: locationDataType): void {
        this.locationData = data
    }

    locationList: any[] = [];
    get getLocationList(){ return this.locationList }
    @Mutation
    setLocationList(data: any[]): void {
        this.locationList = data
    }

    locationAddressData:locationAddressDataType = {};
    get getLocationAddressData(){ return this.locationAddressData }
    @Mutation
    setLocationAddressData(data: locationAddressDataType): void {
        this.locationAddressData = data
    }

}

export const FriendLocationStore = getModule( FriendLocation )
